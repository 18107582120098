import React from "react";
import BodyContentHeader from "../common/BodyContentHeader";
import "./Portfolio.scss";

const Portfolio = () => {
  return (
    <div className="body_content_container_child">
      <BodyContentHeader
        headerTitle="Portfolio"
        headerSubtitle="View some of my projects."
        imgSrc="notebook.svg"
        imgAlt="notebook image"
      />
      <div id="portfolio_projects">
        <div className="ux_portfolio_project_container">
          <div className="ux_project_thumbnail_container">
            <img
              className="ux_project_thumbnail"
              src="auctionart_screens_thumbnail.png"
              alt="auctionart thumbnail"
            />
          </div>
          <div className="ux_portfolio_project_text">
            <h2 className="ux_portfolio_project_h2">AuctionArt</h2>
            <h3 className="ux_portfolio_project_h3">
              This app allows users to place bids on online art auctions. In
              addition, the AuctionArt app aims to address diversity and
              inclusivity issues with contermporary art auctions, as well as
              provide auction opportunities for smaller artists and art
              hobbyists from around the world.
            </h3>

            <a href="/portfolio/AuctionArt" className="purple_btn ">
              View Case Study
            </a>
          </div>
        </div>

        <div className="ux_portfolio_project_container">
          <div className="ux_project_thumbnail_container">
            <img
              className="ux_project_thumbnail"
              src="catmatch_screens_thumbnail.png"
              alt="catmatch thumbnail"
            />
          </div>
          <div className="ux_portfolio_project_text">
            <h2 className="ux_portfolio_project_h2">CatMatch</h2>
            <h3 className="ux_portfolio_project_h3">
              This websites facilitates cat adoptions and matches users with
              cats, based on the unique personalities of the cats using
              playfulness, affection, energy, and talkative scores.
            </h3>

            <a href="/portfolio/CatMatch" className="purple_btn ">
              View Case Study
            </a>
          </div>
        </div>

        <div className="ux_portfolio_project_container">
          <div className="ux_project_thumbnail_container">
            <img
              className="ux_project_thumbnail"
              src="beekind_screens_thumbnail.png"
              alt="bee kind thumbnail"
            />
          </div>
          <div className="ux_portfolio_project_text">
            <h2 className="ux_portfolio_project_h2">Bee Kind</h2>
            <h3 className="ux_portfolio_project_h3">
              Bee Kind, the dedicated mobile app and website where you can learn
              about bee conservation efforts. Search for native plants in your
              area, and find care instructions to help restore the natural
              habitat of bees.
            </h3>

            <a href="/portfolio/BeeKind" className="purple_btn ">
              View Case Study
            </a>
          </div>
        </div>

        <div className="ux_portfolio_project_container">
          <div className="ux_project_thumbnail_container">
            <img
              className="ux_project_thumbnail"
              src="art_banner.png"
              alt="art gallery thumbnail"
            />
          </div>
          <div className="ux_portfolio_project_text">
            <h2 className="ux_portfolio_project_h2">Art Gallery</h2>
            <h3 className="ux_portfolio_project_h3">
              Explore the art gallery. You'll find some of my freelance artwork
              here.
            </h3>

            <a href="/gallery" className="purple_btn ">
              View the gallery
            </a>
          </div>
        </div>

        <div className="ux_portfolio_project_container">
          <div className="ux_project_thumbnail_container">
            <img
              className="ux_project_thumbnail"
              src="space_cat_game_thumbnail.png"
              alt="space cat game thumbnail"
            />
          </div>
          <div className="ux_portfolio_project_text">
            <h2 className="ux_portfolio_project_h2">Play with Space Cats</h2>
            <h3 className="ux_portfolio_project_h3">
              Edgar the space cat is in big trouble. He was transporting food
              through the space station when he accidentally activated the
              artificial gravity! Now all the food is falling! Help him pick it
              up before he gets fired!
            </h3>

            <a href="/game" className="purple_btn ">
              Play the game
            </a>
          </div>
        </div>

        <div className="ux_portfolio_project_container">
          <div className="ux_project_thumbnail_container">
            <img
              className="ux_project_thumbnail"
              src="pokedex_thumbnail.png"
              alt="pokedex project thumbnail"
            />
          </div>
          <div className="ux_portfolio_project_text">
            <h2 className="ux_portfolio_project_h2">Toto's Pokédex</h2>
            <h3 className="ux_portfolio_project_h3">
              This is a live, functional PWA (Progressive Web App) that fetches
              Pokémon information from the community driven, public Pokémon API.
              Made using React.js, TypeScript, and GatsbyJS.
            </h3>
            <div style={{display: "flex", gridGap: "10px"}}>
              <a
                href="https://pokedex.taadland.net/"
                target="_"
                className="purple_btn "
              >
                Visit the website
              </a>
              <a
                href="https://github.com/ZontarZon/taadland_pokemon"
                target="_"
                className="purple_btn "
              >
                View the source code
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
