import React from "react";
import BodyContentHeader from "../common/BodyContentHeader";
import "./Portfolio.scss";

const About = () => {
  return (
    <div>
      <BodyContentHeader
        headerTitle="About Thorya"
        headerSubtitle="The cat's name is Stinky."
        imgSrc="self_portrait.png"
        imgAlt="self portrait"
      />
      <div id="case_study_container">
        <div className="case_study_section_1_col">
          <div className="case_study_row_subsection">
            <div style={{width: "1000px", maxWidth: "100%", margin: "auto"}}>
              <h2>Hi there!</h2>
              <br />
              <h3>
                I'm Thorya Aadland (pronounced TORE-yuh ODD-lund), and I'm a
                frontend web developer, UX designer, and digital artist based in
                South Carolina, USA.
              </h3>
              <br />
              <h3>
                I received my B.S. in Computer science from the University of
                South Carolina. Since then, I have worked several different jobs
                in both software development and the arts. I had several side
                hustles in school, such as artwork and assisting professors in
                their research. I landed my first full-time web development job
                at Van Robotics after graduating, based on some part time work I
                did for the company as I worked on my degree. As for my artwork,
                I have taken commissions for everything from character designs
                to illustrations for research proposals at the university.
              </h3>
              <br />
              <h3>As you can probably tell, I also love space and cats.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
