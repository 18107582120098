import React, {useState} from "react";
import {
  FaCode,
  FaCodeBranch,
  FaCodepen,
  FaDownload,
  FaExternalLinkAlt,
  FaFigma,
  FaMouse,
  FaPalette,
  FaTerminal,
  FaTrello,
} from "react-icons/fa";
import Pdf from "../TAadland_Resume.pdf";
import BodyContentHeader from "../common/BodyContentHeader";
import "./Resume.scss";

const Resume = () => {
  const [resumeIndex, setResumeIndex] = useState(0);

  return (
    <div className="body_content_container_child">
      <BodyContentHeader
        headerTitle="Resume"
        headerSubtitle="Status: Open to work"
        imgSrc="planet_1.svg"
        imgAlt="planet 1"
      />

      <div id="resume_content_container">
        <div id="resume_content_texts">
          <div className={`resume_content_text_${resumeIndex === 0}`}>
            <div style={{fontFamily: "sans-serif", textAlign: "left"}}>
              <h2>Skills</h2>
              <hr />
              <div id="skill_items">
                <div className="skill_item">
                  <FaCode color="white" />
                  Python, Django
                </div>
                <div className="skill_item">
                  <FaCode color="white" />
                  JavaScript/ES6
                </div>
                <div className="skill_item">
                  <FaTerminal color="white" />
                  PostgreSQL
                </div>
                <div className="skill_item">
                  <FaCodeBranch color="white" />
                  Git, GitHub
                </div>
                <div className="skill_item">
                  <FaCodepen color="white" />
                  React.js, Three.js
                </div>
                <div className="skill_item">
                  <FaMouse color="white" />
                  HTML/CSS/Sass
                </div>
                <div className="skill_item">
                  <FaMouse color="white" />
                  Npm, Yarn
                </div>
                <div className="skill_item">
                  <FaTrello color="white" />
                  Jira, Trello
                </div>
                <div className="skill_item">
                  <FaFigma color="white" />
                  Figma, Adobe XD
                </div>
                <div className="skill_item">
                  <FaFigma color="white" />
                  Prototypes
                </div>
                <div className="skill_item">
                  <FaPalette color="white" />
                  Adobe Creative Cloud
                </div>
              </div>
            </div>
          </div>

          <div className={`resume_content_text_${resumeIndex === 1}`}>
            <div style={{fontFamily: "sans-serif", textAlign: "left"}}>
              <h2>Experience - Van Robotics</h2>
              <hr />
              <b>
                <i>
                  Van Robotics, Columbia SC - UI Designer/Developer (July 2018 -
                  August 2023)
                </i>
              </b>
              <ul>
                <li>
                  Designed and developed websites with Django, JavaScript, and
                  React.js to accompany the ABii robot, a smart robot tutor for
                  assisting children with math, language arts, and SEL skills.
                </li>
                <li>
                  Ensured WCAG 2.1 and ADA compliance through audits and
                  rigorous testing.
                </li>
                <li>
                  Ensured mobile compatibility through mobile-first design
                  strategies.
                </li>
                <li>Created wireframes, mockups, and prototypes with Figma.</li>
                <li>
                  Assisted other developers in project testing phases through
                  GitHub peer review, Jira, and Trello.
                </li>
              </ul>
            </div>
          </div>

          <div className={`resume_content_text_${resumeIndex === 2}`}>
            <div style={{fontFamily: "sans-serif", textAlign: "left"}}>
              <h2>Experience - USC Center for Digital Humanities</h2>
              <hr />
              <b>
                <i>
                  USC Center for Digital Humanities, Columbia SC - Full-Stack
                  Web Developer (May 2018 - August 2018)
                </i>
              </b>
              <ul>
                <li>
                  Developed a web archive of historical media, on behalf of the
                  African American Studies department at the University of South
                  Carolina.
                </li>
                <li>
                  Assisted in the UX design of the WardOne app, which contained
                  a digital tour of historic downtown Columbia.
                </li>
                <li>
                  Utilized the Django REST framework to facilitate API
                  communication between the backend and frontend.
                </li>
              </ul>
            </div>
          </div>

          <div className={`resume_content_text_${resumeIndex === 3}`}>
            <div style={{fontFamily: "sans-serif", textAlign: "left"}}>
              <h2>Experience - Freelance Digital Artist</h2>
              <hr />
              <b>
                <i>
                  Independent Contractor, Columbia, SC - Freelance Digital
                  Artist (January 2022 - present)
                </i>
              </b>
              <ul>
                <li>
                  Accepted commissions and contracts for independent
                  illustration and character design work.
                </li>
                <li>Managed finances, handled pricing, and wrote invoices.</li>
                <li>
                  Collaborated with clients, and negotiated copyright terms and
                  conditions for commercial use.
                </li>
              </ul>
            </div>
          </div>

          <div className={`resume_content_text_${resumeIndex === 4}`}>
            <div style={{fontFamily: "sans-serif", textAlign: "left"}}>
              <h2>Education - Degree</h2>
              <hr />
              <h3 style={{margin: 0}}>
                University of South Carolina, Columbia SC
              </h3>
              <b>
                <i>B.S. in Computer Science, Minor in Media Arts (2018)</i>
              </b>
            </div>
          </div>

          <div className={`resume_content_text_${resumeIndex === 5}`}>
            <div style={{fontFamily: "sans-serif", textAlign: "left"}}>
              <h2>Education - Certificates</h2>
              <hr />

              <div className="certifiate_container">
                <h3 style={{margin: 0}}>Google UX Design Certificate</h3>
                <b>
                  <i>Coursera</i>
                </b>

                <p style={{fontWeight: "lighter", margin: "0px 0px 20px 0px"}}>
                  Issued April 2023 - No expiration date
                </p>
                <p>
                  <a
                    className="certificate_link"
                    href="https://www.coursera.org/account/accomplishments/specialization/certificate/YY9U2G8NW3YV"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View credential{" "}
                    <FaExternalLinkAlt style={{marginLeft: "10px"}} />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="resume_btns">
          <button
            className={`resume_btn_${resumeIndex === 0}`}
            onClick={() => setResumeIndex(0)}
          >
            Skills
          </button>
          <div className={`resume_btn_false experience_btn`}>Experience</div>
          <button
            className={`resume_btn_${resumeIndex === 1} subgroup`}
            onClick={() => setResumeIndex(1)}
          >
            <div className="subgroup_square"></div>Van Robotics
          </button>
          <button
            className={`resume_btn_${resumeIndex === 2} subgroup`}
            onClick={() => setResumeIndex(2)}
          >
            <div className="subgroup_square"></div>USC Center for Digital
            Humanities
          </button>
          <button
            className={`resume_btn_${resumeIndex === 3} subgroup`}
            onClick={() => setResumeIndex(3)}
          >
            <div className="subgroup_square"></div>Freelance Artist
          </button>
          <div className={`resume_btn_false experience_btn`}>Education</div>
          <button
            className={`resume_btn_${resumeIndex === 4} subgroup`}
            onClick={() => setResumeIndex(4)}
          >
            <div className="subgroup_square"></div>Degree
          </button>

          <button
            className={`resume_btn_${resumeIndex === 5} subgroup`}
            onClick={() => setResumeIndex(5)}
          >
            <div className="subgroup_square"></div>Certificates
          </button>

          <a
            id="resume_download_link"
            className={`resume_btn_false`}
            href={Pdf}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaDownload />
            {` `}Download PDF
          </a>
        </div>
      </div>
    </div>
  );
};

export default Resume;
