import React, {useEffect, useRef, useState} from "react";
import AAAffinityDiagram from "../../AuctionArtCaseStudyImgs/auctionart_affinity_diagram.png";
import AACompetitiveAuditScreenshot from "../../AuctionArtCaseStudyImgs/auctionart_competitive_audit_screenshot.png";
import AAHiFiScreenshotsImg from "../../AuctionArtCaseStudyImgs/auctionart_hi_fi_prototype_screenshots.png";
import AALoFiScreenshotsImg from "../../AuctionArtCaseStudyImgs/auctionart_low_fi_prototype_screenshots.png";
import AAMockupEdits from "../../AuctionArtCaseStudyImgs/auctionart_mockup_edits.png";
import AAMockups from "../../AuctionArtCaseStudyImgs/auctionart_mockups.png";
import AADrawnWireframes from "../../AuctionArtCaseStudyImgs/auctionart_paper_wireframes.png";
import AAParticipantScreenshot from "../../AuctionArtCaseStudyImgs/auctionart_participant_screenshot.png";
import AAPersona from "../../AuctionArtCaseStudyImgs/auctionart_persona.png";
import AASampleArtPage from "../../AuctionArtCaseStudyImgs/auctionart_sample_art_page.png";
import AAUserJourneyMap from "../../AuctionArtCaseStudyImgs/auctionart_user_journey_map.png";
import AAEditsOne from "../../AuctionArtCaseStudyImgs/auctionart_wireframe_edits_1.png";
import AAEditsTwo from "../../AuctionArtCaseStudyImgs/auctionart_wireframe_edits_2.png";

import BodyContentHeader from "../../common/BodyContentHeader";
import "./CaseStudy.scss";

const AuctionArtCaseStudy = () => {
  const TopRef = useRef(null);
  const IntroductionRef = useRef(null);
  const UserResearchRef = useRef(null);
  const StartingTheDesignRef = useRef(null);
  const UsabilityStudyRef = useRef(null);
  const RefiningTheDesignRef = useRef(null);
  const MockupsRef = useRef(null);
  const GoingForwardRef = useRef(null);
  const CaseStudyNavRef = useRef(null);
  const IntroImgRef = useRef(null);

  const [caseStudyNavBarFixed, setCaseStudyNavBarFixed] = useState("relative");

  const scrollToSection = (Ref) => {
    Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
  };

  const stickNavbar = (e) => {
    if (window !== undefined) {
      if (
        IntroImgRef.current.getBoundingClientRect().top < 60 &&
        caseStudyNavBarFixed !== "fixed"
      ) {
        setCaseStudyNavBarFixed("fixed");
      } else if (
        IntroImgRef.current.getBoundingClientRect().top > 60 &&
        caseStudyNavBarFixed !== "relative"
      ) {
        setCaseStudyNavBarFixed("relative");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar, true);
    return () => {
      window.removeEventListener("scroll", stickNavbar, true);
    };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseStudyNavBarFixed]);

  return (
    <div ref={TopRef} className="body_content_container_child">
      <BodyContentHeader
        headerTitle="AuctionArt"
        headerSubtitle="Place bids on art auctions."
        imgSrc="../notebook.svg"
        imgAlt="notebook image"
      />

      <div
        ref={CaseStudyNavRef}
        id="case_study_nav_bar"
        className={`${caseStudyNavBarFixed}`}
      >
        <button onClick={() => scrollToSection(IntroductionRef)}>
          Intro
        </button>
        <button onClick={() => scrollToSection(UserResearchRef)}>
          User Research
        </button>
        <button onClick={() => scrollToSection(StartingTheDesignRef)}>
          Starting the Design
        </button>
        <button onClick={() => scrollToSection(UsabilityStudyRef)}>
          Usability Study
        </button>
        <button onClick={() => scrollToSection(RefiningTheDesignRef)}>
          Refining the design
        </button>
        <button onClick={() => scrollToSection(MockupsRef)}>Mockups</button>
        <button onClick={() => scrollToSection(GoingForwardRef)}>
          Going Forward
        </button>
        <button onClick={() => scrollToSection(TopRef)}>Back to top</button>
      </div>

      <div ref={IntroImgRef} id="case_study_container">
        <div id="case_study_intro_img_container">
          <img
            id="case_study_intro_img"
            src={AAHiFiScreenshotsImg}
            alt="auction art case study intro pic"
          />
        </div>

        <h1 ref={IntroductionRef}>Introduction</h1>

        <div className="case_study_section_2_col">
          <div className="case_study_col_subsection">
            <h2>The Product:</h2>
            <h3>
              AuctionArt is a dedicated mobile app for placing bids on art
              auctions.
            </h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>Duration:</h2>
            <h3>October 2022 - January 2023</h3>
          </div>

          <div className="case_study_col_subsection">
            <h2>The Challenge:</h2>
            <h3>
              Many online art auctions are exclusive to wealthy patrons and
              collectors. Auction websites often lack diversity in their mediums
              and featured artists, restricting their content to 2D art and
              failing to feature traditionally underrepresented groups.
            </h3>
          </div>

          <div className="case_study_col_subsection">
            <h2>The Goal:</h2>
            <h3>
              The AuctionArt mobile app will let users browse discounted online
              art auctions, which will affect users with limited funds who can’t
              afford to spend thousands on artwork, by advertising auction sales
              and promotions from small-scale artists and businesses from around
              the world.
            </h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>Audience:</h2>
            <h3>Art hobbyists, small businesses, small artists, and people exploring the hobby.</h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>My Role:</h2>
            <h3>
              UX Designer
            </h3>
            <h3>
              Responsible for ideation, user research, building wireframes, user testing,
              building mockups, prototyping
            </h3>
          </div>
        </div>

        <h1 ref={UserResearchRef}>User Research</h1>

        <div className="case_study_section_1_col">
          <h3>
            To better understand my users, I made some personas, created user
            journey maps, and conducted a competitive audit. Later on, I tested
            a low-fidelity prototype with an unmoderated usability study.
          </h3>

          <div className="case_study_row_subsection">
            <img src={AAPersona} alt="auction art persona" />
            <div className="case_study_col_subsection">
              <h2>Problem statement:</h2>
              <h3>
                "Heather is a prospective homemaker and new homeowner who needs
                to browse collections of art by material, medium, and color,
                because she wants to decorate her house with decor that suits
                her style and won’t break easily."
              </h3>
            </div>
          </div>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>User Journey Map</h2>
              <h3>
                The aim was to make the bidding process as simplistic as
                possible, while also accommodating for the pathways a user can
                take to get there. The path is designed to be cyclic, meaning
                the user can enter/exit from any point and navigate back to
                bidding.
              </h3>
            </div>
            <img src={AAUserJourneyMap} alt="auction art user journey map" />
          </div>

          <div className="case_study_row_subsection">
            <img
              src={AACompetitiveAuditScreenshot}
              alt="auction art competitive audit screenshot"
            />
            <div className="case_study_col_subsection">
              <h2>Competitive Audit</h2>
              <h3>
                To determine opportunities for filling in gaps in the market
                needs, I conducted a competitive audit on three competitors:
                Artnet, Artsy, and Etsy. I assessed them based on the overall
                website experience, features, accessibility, user flow,
                navigation, brand identity, tone, and descriptiveness.
              </h3>
            </div>
          </div>
        </div>

        <h1 style={{textAlign: "center", fontSize: "35px"}}>
          The pain points:
        </h1>

        <div id="pain_points_container">
          <div className="pain_point">
            <div className="pain_point_number">1</div>
            <h3 className="pain_point_header">
              <b>Pricing</b>
            </h3>
            <div className="pain_point_desc">
              Most online art auctions exceed thousands of US Dollars, making
              them vastly out of budget range for casual hobbyists and art
              enthusiasts.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">2</div>
            <h3 className="pain_point_header">
              <b>Lack of diversity</b>
            </h3>
            <div className="pain_point_desc">
              Most featured auction mediums are limited to 2D artwork, such as
              photography, prints, and paintings. This leaves massive gaps in
              diversity for art mediums and artisans.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">3</div>
            <h3 className="pain_point_header">
              <b>Ease of accessibility</b>
            </h3>
            <div className="pain_point_desc">
              People with disabilities, such as visual impairment, can still
              take a keen interest in art and should be considered when
              designing art auction sites.
            </div>
          </div>
        </div>

        <h1 ref={StartingTheDesignRef}>Starting the Design</h1>

        <div className="case_study_large_img_container">
          <img
            className="case_study_large_img"
            src={AADrawnWireframes}
            alt="auction art hand drawn wireframes"
          />
        </div>

        <div className="case_study_section_1_col">
          <h3>
            To design the app, I began with drafting the wireframes. I ideated
            many design solutions, and I added stars to the aspects I liked the
            most. Later, I compiled these aspects and created digital designs in
            Figma.
          </h3>

          <div className="case_study_row_subsection">
            <img
              src={AASampleArtPage}
              alt="auction art sample art wireframe page"
            />
            <div className="case_study_col_subsection">
              <h2>Digital Wireframes</h2>
              <h3>
                The goal was to make browsing as cyclic and easy to navigate as
                possible. The browsing pages can be very lengthy and full of
                information, so it was crucial to apply Gestalt principles and
                divide the content well.
              </h3>
              <h3>
                There was a large focus on the immediate gratification of
                information, so the relative importance of info is reflected in
                the page layout, font size, and order.
              </h3>
            </div>
          </div>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>Low Fidelity Prototype</h2>
              <h3>
                The goal was to make browsing as cyclic and easy to navigate as
                possible. The browsing pages can be very lengthy and full of
                information, so it was crucial to divide the content well. There
                was a large focus on the immediate gratification of info, so the
                relative importance of info is reflected in the page layout,
                font size, and order.
              </h3>

              <a
                className="purple_btn"
                href="https://www.figma.com/proto/wWug7Nqv4RAKbb3SP61Owe/Wireframes?node-id=1%3A2&scaling=scale-down&page-id=0%3A1&starting-point-node-id=1%3A2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Test the Low-Fi Prototype
              </a>
            </div>
            <img
              src={AALoFiScreenshotsImg}
              alt="auction art low fidelity prototype screenshots"
            />
          </div>
        </div>

        <h1 ref={UsabilityStudyRef}>Usability Study</h1>

        <div className="case_study_section_2_col">
          <div className="case_study_col_subsection">
            <h2>Research Goal:</h2>
            <h3>
              Determine if users can complete core tasks, such as placing a bid
              on artwork, within the AuctionArt low fidelity prototype.
            </h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>Duration:</h2>
            <h3>January 8-14, 2023</h3>
          </div>

          <div className="case_study_col_subsection">
            <h2>Methodology:</h2>
            <h3>
              Remote, unmoderated usability study. Users followed prompts while
              recording their screen and voice as they tested the site.
            </h3>
          </div>

          <div className="case_study_col_subsection">
            <h2>Participants:</h2>
            <h3>
              Two males, two females, and one non-binary individual, all between
              the ages of 18 and 75. One participant has visual impairment.
            </h3>
          </div>
        </div>

        <div className="case_study_section_1_col">
          <div className="case_study_row_subsection">
            <img
              src={AAParticipantScreenshot}
              alt="auction art participant feedback screenshot"
            />
            <div className="case_study_col_subsection">
              <h2>Participant Feedback</h2>
              <h3>
                Participant click paths, feelings, and significant quotes were
                documented on a google sheet.
              </h3>
            </div>
          </div>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>Organizing the Data</h2>
              <h3>
                Participant feedback was organized in an affinity diagram, which
                I used to create my findings.
              </h3>
            </div>
            <img src={AAAffinityDiagram} alt="auction art affinity diagram" />
          </div>
        </div>

        <h1 style={{textAlign: "center", fontSize: "35px"}}>Findings:</h1>

        <div id="pain_points_container">
          <div className="pain_point">
            <div className="pain_point_number">1</div>
            <h3 className="pain_point_header">
              <b>
                Users are not guaranteed to know what the term “listing” means.
              </b>
            </h3>
            <div className="pain_point_desc">
              Some of the jargon of the art community must be removed, as users
              who are new to the hobby may not know what it means.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">2</div>
            <h3 className="pain_point_header">
              <b>
                Most participants anticipate a back button on the listing info
                pages.
              </b>
            </h3>
            <div className="pain_point_desc">
              Users expressed a desire to preserve their searches by a back
              button on the artwork's info page, which would allow them to
              adjust their search and not lose their place.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">3</div>
            <h3 className="pain_point_header">
              <b>
                Users may desire integrated payment options, such as PayPal.
              </b>
            </h3>
            <div className="pain_point_desc">
              Some users felt like integrated payment options would be easier
              and more secure.
            </div>
          </div>
        </div>

        <h1 ref={RefiningTheDesignRef}>Refining the design</h1>

        <div className="case_study_section_1_col">
          <h3>
            After the usability study was completed, I used the feedback to make
            adjustments to the designs.
          </h3>

          <div className="case_study_row_subsection">
            <img
              src={AAEditsOne}
              alt="auction art wireframe edits of back button"
            />
            <div className="case_study_col_subsection">
              <h2>Improvements: Back button</h2>
              <h3>
                A back button was incorporated, so the user won't have to
                revisit the search page via the navigation bar, which would lose
                their search settings.
              </h3>
            </div>
          </div>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>Improvements: Integrated payment options</h2>
              <h3>
                The payment page was adjusted to offer several forms of payment,
                including Credit/Debit, Paypal, Google Pay, and Apple Pay. If
                these payment options are set up, the user won't have to
                manually input payment info, making the process much faster and
                easier.
              </h3>
            </div>
            <img
              src={AAEditsTwo}
              alt="auction art wireframe edits of payment options"
            />
          </div>
        </div>

        <h1 ref={MockupsRef}>Mockups</h1>

        <div className="case_study_large_img_container">
          <img
            className="case_study_large_img"
            src={AAMockups}
            alt="auction art mockups"
          />
        </div>

        <div className="case_study_section_1_col">
          <h3>
            Using the refined wireframes, I created mockups in Figma and
            incorporated a soft, pastel orange color palette. I reserved the
            darker, more saturated orange for calls to action and high priority
            visuals, such as the login button and buttons to navigate through
            the bidding process.
          </h3>

          <div className="case_study_row_subsection">
            <img src={AAMockupEdits} alt="auction art mockup edits" />
            <div className="case_study_col_subsection">
              <h2>Improvements: sectioning and delivery instructions</h2>
              <h3>
                Looking at the first draft of the mockups, I realized that there
                was just too much black and white on the payments page. Payment
                and shipping needed to be clearly separated, otherwise the
                contents would be muddled. I added cream colored headers to help
                separate these sections.
              </h3>
              <h3>
                Since artwork is often very delicate, I also added an optional
                field for delivery instructions.
              </h3>
            </div>
          </div>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>High Fidelity Prototype</h2>
              <h3>
                Transition animations, typography, images of real artwork, and a
                color palette bring a high fidelity prototype to life, and it
                gives us a better understanding of what the app would really
                feel like.
              </h3>

              <a
                className="purple_btn"
                href="https://www.figma.com/proto/wWug7Nqv4RAKbb3SP61Owe/Wireframes?node-id=74%3A3&scaling=scale-down&page-id=74%3A2&starting-point-node-id=74%3A3"
                target="_blank"
                rel="noopener noreferrer"
              >
                Test the High-Fi Prototype
              </a>
            </div>
            <img
              src={AAHiFiScreenshotsImg}
              alt="auction art high fidelity prototype screenshots"
            />
          </div>
        </div>

        <h1 style={{textAlign: "center", fontSize: "35px"}}>
          Accessibility Considerations:
        </h1>

        <div id="pain_points_container">
          <div className="pain_point">
            <div className="pain_point_number">1</div>
            <h3 className="pain_point_header">
              <b>Use of accompanying text with every image and thumbnail.</b>
            </h3>
            <div className="pain_point_desc">
              During the competitive audit, it was found that many existing
              websites provide thumbnails but don’t have any accompanying
              captions or alt tags, making it hard on those who use screen
              readers.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">2</div>
            <h3 className="pain_point_header">
              <b>High contrast ratio.</b>
            </h3>
            <div className="pain_point_desc">
              Color blindness is a prominent issue and requires its own
              specifications. Providing a high contrast ratio also benefits
              everyone, as it allows us to draw focus to specific sections and
              calls to action.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">3</div>
            <h3 className="pain_point_header">
              <b>Adjusting to the user’s selected region and language.</b>
            </h3>
            <div className="pain_point_desc">
              Not everyone is shipping to the same country. Accommodations for
              different currencies and formats of addresses are a must.
            </div>
          </div>
        </div>

        <h1 ref={GoingForwardRef}>Going Forward</h1>

        <div className="case_study_section_2_col">
          <div className="case_study_col_subsection">
            <h2>Impact:</h2>
            <h3>
              The AuctionArt app designs resulted in usability study
              participants feeling more confident in their ability to
              participate in art auctions. It’s a great way for both
              professional and beginner hobbyists to enjoy and support artists.
            </h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>What I learned:</h2>
            <h3>
              This was my first ever case study and UX project, created for my
              Google UX Design certificate. I learned a great deal about user
              research and accessibility during this project. I also learned
              advanced prototyping skills in Figma. This experience has
              definitely improved the overall quality of my work, in both UX
              design and software development.
            </h3>
          </div>
        </div>

        <h1 style={{textAlign: "center", fontSize: "35px"}}>
          My Recommended Next Steps:
        </h1>

        <div id="pain_points_container">
          <div className="pain_point">
            <div className="pain_point_number">1</div>
            <h3 className="pain_point_header">
              <b>Additional research for users with disabilities.</b>
            </h3>
            <div className="pain_point_desc">
              The usability studies included visually impaired users, but it
              would be greatly beneficial to test with other accessibility
              features, such as those for users with limited mobility.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">2</div>
            <h3 className="pain_point_header">
              <b>In-app carousels for multiple images of artwork.</b>
            </h3>
            <div className="pain_point_desc">
              Some artwork will require more than one photograph. Ceramics,
              sculptures, and other 3D works would benefit from this.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">3</div>
            <h3 className="pain_point_header">
              <b>Charity auctions.</b>
            </h3>
            <div className="pain_point_desc">
              Many art auctions are for the sake of charity, like silent
              auctions. The proceeds go towards a humanitarian cause.
            </div>
          </div>
        </div>
        <div style={{display: "flex"}}>
          <img
            style={{width: "300px", maxWidth: "100%", margin: "auto"}}
            src="../teila_head.png"
            alt="teila's head"
          />
        </div>
        <h1 style={{textAlign: "center", fontSize: "35px", margin: "0px"}}>
          Thank you!
        </h1>
      </div>
    </div>
  );
};

export default AuctionArtCaseStudy;
