import React from "react";
import {
  FaHouseUser,
  FaList,
  FaMale,
  FaPaperclip
} from "react-icons/fa";

const NavBar = ({openNavSideBar}) => {
  return (
    <div id="links_container" style={{left: openNavSideBar ? "0px" : "-200px"}}>
      <a href="/" className="nav_btn border-gradient border-gradient-purple">
        <FaHouseUser />
        <p>Home</p>
      </a>
      <a
        href="/about"
        className="nav_btn border-gradient border-gradient-purple"
      >
        <FaMale />
        <p>About</p>
      </a>
      <a
        href="/resume"
        className="nav_btn border-gradient border-gradient-purple"
      >
        <FaList />
        <p>Resume</p>
      </a>
      <a
        href="/portfolio"
        className="nav_btn border-gradient border-gradient-purple"
      >
        <FaPaperclip />
        <p>Portfolio</p>
      </a>
    </div>
  );
};

export default NavBar;
