import React, {useEffect, useRef, useState} from "react";
import CMCompetitiveAuditScreenshot from "../../CatMatchCaseStudyImgs/catmatch_competitive_audit_screenshot.png";
import CMHiFiScreenshotsImg from "../../CatMatchCaseStudyImgs/catmatch_hi_fi_prototype_screenshots.png";
import CMLoFiScreenshotsImg from "../../CatMatchCaseStudyImgs/catmatch_low_fi_prototype_screenshots.png";
import CMMockupEdits from "../../CatMatchCaseStudyImgs/catmatch_mockup_edits.png";
import CMMockups from "../../CatMatchCaseStudyImgs/catmatch_mockups.png";
import CMDrawnWireframes from "../../CatMatchCaseStudyImgs/catmatch_paper_wireframes.png";
import CMParticipantScreenshot from "../../CatMatchCaseStudyImgs/catmatch_participant_screenshot.png";
import CMPersona from "../../CatMatchCaseStudyImgs/catmatch_persona.png";
import CMSampleInfoPage from "../../CatMatchCaseStudyImgs/catmatch_sample_info_page.png";
import CMUserJourneyMap from "../../CatMatchCaseStudyImgs/catmatch_user_journey_map.png";
import CMEditsOne from "../../CatMatchCaseStudyImgs/catmatch_wireframe_edits_1.png";
import CMEditsTwo from "../../CatMatchCaseStudyImgs/catmatch_wireframe_edits_2.png";

import BodyContentHeader from "../../common/BodyContentHeader";
import "./CaseStudy.scss";

const CatMatchCaseStudy = () => {
  const TopRef = useRef(null);
  const IntroductionRef = useRef(null);
  const UserResearchRef = useRef(null);
  const StartingTheDesignRef = useRef(null);
  const UsabilityStudyRef = useRef(null);
  const RefiningTheDesignRef = useRef(null);
  const MockupsRef = useRef(null);
  const GoingForwardRef = useRef(null);
  const CaseStudyNavRef = useRef(null);
  const IntroImgRef = useRef(null);

  const [caseStudyNavBarFixed, setCaseStudyNavBarFixed] = useState("relative");

  const scrollToSection = (Ref) => {
    Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
  };

  const stickNavbar = (e) => {
    if (window !== undefined) {
      if (
        IntroImgRef.current.getBoundingClientRect().top < 60 &&
        caseStudyNavBarFixed !== "fixed"
      ) {
        setCaseStudyNavBarFixed("fixed");
      } else if (
        IntroImgRef.current.getBoundingClientRect().top > 60 &&
        caseStudyNavBarFixed !== "relative"
      ) {
        setCaseStudyNavBarFixed("relative");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar, true);
    return () => {
      window.removeEventListener("scroll", stickNavbar, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseStudyNavBarFixed]);

  return (
    <div ref={TopRef} className="body_content_container_child">
      <BodyContentHeader
        headerTitle="CatMatch"
        headerSubtitle="Adopt your new best friend."
        imgSrc="../notebook.svg"
        imgAlt="notebook image"
      />

      <div
        ref={CaseStudyNavRef}
        id="case_study_nav_bar"
        className={`${caseStudyNavBarFixed}`}
      >
        <button onClick={() => scrollToSection(IntroductionRef)}>Intro</button>
        <button onClick={() => scrollToSection(UserResearchRef)}>
          User Research
        </button>
        <button onClick={() => scrollToSection(StartingTheDesignRef)}>
          Starting the Design
        </button>
        <button onClick={() => scrollToSection(UsabilityStudyRef)}>
          Usability Study
        </button>
        <button onClick={() => scrollToSection(RefiningTheDesignRef)}>
          Refining the design
        </button>
        <button onClick={() => scrollToSection(MockupsRef)}>Mockups</button>
        <button onClick={() => scrollToSection(GoingForwardRef)}>
          Going Forward
        </button>
        <button onClick={() => scrollToSection(TopRef)}>Back to top</button>
      </div>

      <div ref={IntroImgRef} id="case_study_container">
        <div id="case_study_intro_img_container">
          <img
            id="case_study_intro_img"
            src="../catmatch_screens_thumbnail.png"
            alt="cat match case study intro pic"
          />
        </div>

        <h1 ref={IntroductionRef}>Introduction</h1>

        <div className="case_study_section_2_col">
          <div className="case_study_col_subsection">
            <h2>The Product:</h2>
            <h3>CatMatch is a website for cat adoptions.</h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>Duration:</h2>
            <h3>December 2022 - February 2023</h3>
          </div>

          <div className="case_study_col_subsection">
            <h2>The Challenge:</h2>
            <h3>
              There are lots of cat adoption websites, but very few of them
              accommodate for the unique personalities of each cat. Because many
              sites only offer location and breed information, people often
              don’t know how a cat will adjust to their household until they
              meet the cat in person and take it home.
            </h3>
          </div>

          <div className="case_study_col_subsection">
            <h2>The Goal:</h2>
            <h3>
              CatMatch aims to match cats with potential new owners by feline
              personality traits. Users can search for cats based on their
              affection, energy, playfulness, and talkative scores.
            </h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>Audience:</h2>
            <h3>
              People seeking to adopt a cat, learn more about cat adoption, and
              learn more about cat care.
            </h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>My Role:</h2>
            <h3>UX Designer</h3>
            <h3>
              Responsible for ideation, user research, building wireframes, user
              testing, building mockups, prototyping
            </h3>
          </div>
        </div>

        <h1 ref={UserResearchRef}>User Research</h1>

        <div className="case_study_section_1_col">
          <h3>
            To better understand my users, I made some personas, created user
            journey maps, and conducted a competitive audit. Later on, I tested
            a low-fidelity prototype with an unmoderated usability study.
          </h3>

          <div className="case_study_row_subsection">
            <img src={CMPersona} alt="cat match persona" />
            <div className="case_study_col_subsection">
              <h2>Problem statement:</h2>
              <h3>
                "Edgar Hoffmann is a 40 year old data analyst from Raleigh, NC
                who needs to adopt a gentle-natured, hypoallergenic cat because
                he wants the social-emotional benefits of cat ownership while
                also minding his allergies."
              </h3>
            </div>
          </div>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>User Journey Map</h2>
              <h3>
                The aim was to ideate the thought processes of someone looking
                for a cat with specific traits. For Edgar, he needs to find a
                hypoallergenic cat. Though this journey map, I ideated a way he
                would satisfy this need.
              </h3>
            </div>
            <img src={CMUserJourneyMap} alt="cat match user journey map" />
          </div>

          <div className="case_study_row_subsection">
            <img
              src={CMCompetitiveAuditScreenshot}
              alt="cat match competitive audit screenshot"
            />
            <div className="case_study_col_subsection">
              <h2>Competitive Audit</h2>
              <h3>
                To determine opportunities for filling in gaps in the market
                needs, I conducted a competitive audit on three competitors:
                PetSmart Charities, Petfinder, and Feline Finder App. I assessed
                them based on the overall website experience, features,
                accessibility, user flow, navigation, brand identity, tone, and
                descriptiveness.
              </h3>
            </div>
          </div>
        </div>

        <h1 style={{textAlign: "center", fontSize: "35px"}}>
          The pain points:
        </h1>

        <div id="pain_points_container">
          <div className="pain_point">
            <div className="pain_point_number">1</div>
            <h3 className="pain_point_header">
              <b>Limited cat information</b>
            </h3>
            <div className="pain_point_desc">
              Users want to know more than just the breed, age, and sex of a cat
              before adopting. They want more specifics, such as temperament,
              health statuses, etc.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">2</div>
            <h3 className="pain_point_header">
              <b>Compatibility concerns</b>
            </h3>
            <div className="pain_point_desc">
              Users may have allergies, physical limitations, and/or other pets,
              which may hinder their ability to find a compatible cat.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">3</div>
            <h3 className="pain_point_header">
              <b>Lack of concise, focused user flow</b>
            </h3>
            <div className="pain_point_desc">
              The competitive audit found that may sites continuously draw the
              user’s attention away from their activities with popups and
              constant requests for donations.
            </div>
          </div>
        </div>

        <h1 ref={StartingTheDesignRef}>Starting the Design</h1>

        <div className="case_study_large_img_container">
          <img
            className="case_study_large_img"
            src={CMDrawnWireframes}
            alt="cat match hand drawn wireframes"
          />
        </div>

        <div className="case_study_section_1_col">
          <h3>
            To design the app, I began with drafting the wireframes. Several
            page layouts were drafted, and the aspects I liked the most were
            starred. These were combined in the digital renditions later in
            Adobe XD. I made sure to choose designs that could transition easily
            into a mobile version of the site.
          </h3>

          <div className="case_study_row_subsection">
            <img
              src={CMSampleInfoPage}
              alt="cat match sample cat info wireframe page"
            />
            <div className="case_study_col_subsection">
              <h2>Digital Wireframes</h2>
              <h3>
                Knowing that CatMatch would inevitably be used by mobile
                devices, I chose components that could easily transition to
                mobile.
              </h3>
              <h3>
                The digital wireframes were built in Adobe XD and were later
                tested in a usability study.
              </h3>
            </div>
          </div>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>Low Fidelity Prototype</h2>
              <h3>
                The goal was to cover the bases for a cat search, as well as
                include additional criteria that would allow users to better
                choose a cat with the right personality and health traits for
                their needs.
              </h3>

              {/* Disabled due to expired adobe subscription.
              <a
                className="purple_btn"
                href="https://xd.adobe.com/view/fd7ba79b-9fe7-42a0-a08c-4e3936dcb6c8-a828/?fullscreen"
                target="_blank"
                rel="noopener noreferrer"
              >
                Test the Low-Fi Prototype
            </a>*/}
            </div>
            <img
              src={CMLoFiScreenshotsImg}
              alt="cat match low fidelity prototype screenshots"
            />
          </div>
        </div>

        <h1 ref={UsabilityStudyRef}>Usability Study</h1>

        <div className="case_study_section_2_col">
          <div className="case_study_col_subsection">
            <h2>Research Goal:</h2>
            <h3>
              Determine if users can complete core tasks, such as searching for
              a cat and submitting an inquiry for adoption.
            </h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>Duration:</h2>
            <h3>February 5-11, 2023</h3>
          </div>

          <div className="case_study_col_subsection">
            <h2>Methodology:</h2>
            <h3>
              Remote, unmoderated usability study. Users followed prompts while
              recording their screen and voice as they tested the site.
            </h3>
          </div>

          <div className="case_study_col_subsection">
            <h2>Participants:</h2>
            <h3>
              One male, two females, all between the ages of 18 and 75. One
              participant has visual impairment.
            </h3>
          </div>
        </div>

        <div className="case_study_section_1_col">
          <div className="case_study_row_subsection">
            <img
              src={CMParticipantScreenshot}
              alt="cat match participant feedback screenshot"
            />
            <div className="case_study_col_subsection">
              <h2>Participant Feedback</h2>
              <h3>
                Participant click paths, feelings, and significant quotes were
                documented on a google sheet.
              </h3>
            </div>
          </div>
        </div>

        <h1 style={{textAlign: "center", fontSize: "35px"}}>Findings:</h1>

        <div id="pain_points_container">
          <div className="pain_point">
            <div className="pain_point_number">1</div>
            <h3 className="pain_point_header">
              <b>
                They use the personality scores and compare them to their own
                pets.
              </b>
            </h3>
            <div className="pain_point_desc">
              For all three participants, their concern for the cat’s
              personality was mainly to assess their compatibility with their
              other pets, not necessarily with themselves.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">2</div>
            <h3 className="pain_point_header">
              <b>Health of the animal is a major concern.</b>
            </h3>
            <div className="pain_point_desc">
              Participants placed health as a very high priority when looking
              for a new pet. They were especially careful to look for cats that
              were spayed/neutered and vaccinated.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">3</div>
            <h3 className="pain_point_header">
              <b>Users want to keep track of their inquiries.</b>
            </h3>
            <div className="pain_point_desc">
              Participants desired a clear message for when their inquiries are
              sent successfully. They also desired a way to ensure they don’t
              accidentally inquire multiple times for the same cat.
            </div>
          </div>
        </div>

        <h1 ref={RefiningTheDesignRef}>Refining the design</h1>

        <div className="case_study_section_1_col">
          <h3>
            After the usability study was completed, I used the feedback to make
            adjustments to the designs.
          </h3>

          <div className="case_study_row_subsection">
            <img
              src={CMEditsOne}
              alt="cat match wireframe edits of health info changes"
            />
            <div className="case_study_col_subsection">
              <h2>Improvements: Better organized health info</h2>
              <h3>
                Previously, contact info and the health info of the animal were
                both in the same place. Because users desired health info the
                most, I separated these sets.
              </h3>
            </div>
          </div>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>Improvements: Confirmation of inquiry</h2>
              <h3>
                Users wanted to keep track of cats they previously inquired for,
                so I added a new design with a confirmation of a successful
                inquiry submission. I also changed the inquiry button to add a
                checkmark if an inquiry was already submitted.
              </h3>
            </div>
            <img
              src={CMEditsTwo}
              alt="cat match wireframe edits of search options"
            />
          </div>
        </div>

        <h1 ref={MockupsRef}>Mockups</h1>

        <div className="case_study_large_img_container">
          <img
            className="case_study_large_img"
            src={CMMockups}
            alt="cat match mockups"
          />
        </div>

        <div className="case_study_section_1_col">
          <h3>
            Using the refined wireframes, I created mockups in Adobe XD and
            incorporated a soft, pastel orange color palette. I reserved the
            darker, more saturated orange for calls to action and high priority
            visuals, such as the login button and buttons to navigate through
            the bidding process.
          </h3>

          <div className="case_study_row_subsection">
            <img src={CMMockupEdits} alt="cat match mockup edits" />
            <div className="case_study_col_subsection">
              <h2>Improvements: More search options</h2>
              <h3>
                Because all users in the study were concerned about spay/neuter
                and compatibility with animals and kids, I decided to move that
                info to sooner in the user flow.
              </h3>
              <h3>Users can now search by this criteria.</h3>
            </div>
          </div>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>High Fidelity Prototype</h2>
              <h3>
                Typography, images of real cats, and a color palette bring a
                high fidelity prototype to life, and it gives us a better
                understanding of what the site would really feel like.
              </h3>

              {/* Disabled due to expired adobe subscription.
              <a
                className="purple_btn"
                href="https://xd.adobe.com/view/969a0036-12c1-44b9-ab40-ef1d3fd74a82-c3c9/?fullscreen"
                target="_blank"
                rel="noopener noreferrer"
              >
                Test the High-Fi Prototype
            </a>*/}
            </div>
            <img
              src={CMHiFiScreenshotsImg}
              alt="cat match high fidelity prototype screenshots"
            />
          </div>
        </div>

        <h1 style={{textAlign: "center", fontSize: "35px"}}>
          Accessibility Considerations:
        </h1>

        <div id="pain_points_container">
          <div className="pain_point">
            <div className="pain_point_number">1</div>
            <h3 className="pain_point_header">
              <b>Large imagery and iconography.</b>
            </h3>
            <div className="pain_point_desc">
              This helps communicate calls to action, especially for users who
              may not be fluent in English.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">2</div>
            <h3 className="pain_point_header">
              <b>High contrast ratio.</b>
            </h3>
            <div className="pain_point_desc">
              The contrast ratio of the color palette was measured very
              carefully, to ensure that visually impaired users can still
              recognize margins of color.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">3</div>
            <h3 className="pain_point_header">
              <b>Large headers and labels for each section.</b>
            </h3>
            <div className="pain_point_desc">
              Variations in font size and text decoration help to portray the
              priority of information, as well as provide additional assistance
              to users reliant on screen readers.
            </div>
          </div>
        </div>

        <h1 ref={GoingForwardRef}>Going Forward</h1>

        <div className="case_study_section_2_col">
          <div className="case_study_col_subsection">
            <h2>Impact:</h2>
            <h3>
              The CatMatch website designs resulted in usability study
              participants feeling more secure and assured for their future
              adoptions. The openness and full disclosure of the cat’s
              personality and health is an immense boost to their likelihood of
              adoption.
            </h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>What I learned:</h2>
            <h3>
              I learned how to design and prototype projects in Adobe XD. The
              usability study was immensely helpful for connecting and
              empathizing with the wants and needs of users, since the results
              were definitely not what I expected.
            </h3>
          </div>
        </div>

        <h1 style={{textAlign: "center", fontSize: "35px"}}>
          My Recommended Next Steps:
        </h1>

        <div id="pain_points_container">
          <div className="pain_point">
            <div className="pain_point_number">1</div>
            <h3 className="pain_point_header">
              <b>
                Incorporating more helpful articles in the resources section.
              </b>
            </h3>
            <div className="pain_point_desc">
              There’s much more to cat ownership than basic needs. Articles
              about cat behavior and cat stories would delight and educate
              users.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">2</div>
            <h3 className="pain_point_header">
              <b>Additional research for visually impaired users.</b>
            </h3>
            <div className="pain_point_desc">
              CatMatch is very heavy on imagery. It would be beneficial to
              conduct a study to ensure screen reader compatibility.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">3</div>
            <h3 className="pain_point_header">
              <b>Charities and fundraisers.</b>
            </h3>
            <div className="pain_point_desc">
              In the end, not everyone who visits CatMatch will adopt a cat.
              Other options should be available to assist cat adoption, such as
              donating to charitable organizations.
            </div>
          </div>
        </div>
        <div style={{display: "flex"}}>
          <img
            style={{width: "300px", maxWidth: "100%", margin: "auto"}}
            src="../teila_head.png"
            alt="teila's head"
          />
        </div>
        <h1 style={{textAlign: "center", fontSize: "35px", margin: "0px"}}>
          Thank you!
        </h1>
      </div>
    </div>
  );
};

export default CatMatchCaseStudy;
