import React from "react";
import {FaCode, FaEnvelope, FaLinkedin} from "react-icons/fa";
import "../pages/Landing.scss";
import whiteLinesIcon from "../white_lines_icon_1.svg";
import "./BodyContentHeader.scss";

const BodyContentHeader = ({headerTitle, headerSubtitle, imgSrc, imgAlt}) => {
  return (
    <div>
      <div id="body_content_header_container">
        <div id="landing_header_txt_container">
          <div id="landing_h1_container">
            <img
              id="landing_h1_icon"
              alt="white_lines_decor"
              src={whiteLinesIcon}
            />

            <h1 id="body_content_h1">{headerTitle}</h1>
          </div>
          <h4 id="body_content_h4">{headerSubtitle}</h4>
          <div id="contact_info_container">
            <a className="contact_info" href="mailto:thoryagudrun@gmail.com">
              <FaEnvelope />
              <span>Email</span>
            </a>

            <a
              className="contact_info"
              href="https://www.linkedin.com/in/thorya-aadland/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
              <span>LinkedIn</span>
            </a>

            <a
              className="contact_info"
              href="https://stackoverflow.com/users/9457623/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaCode />
              <span>Stackoverflow</span>
            </a>

            <a
              className="contact_info"
              href="https://github.com/ZontarZon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaCode />
              <span>GitHub</span>
            </a>
          </div>
        </div>
        <div className="header_img_container">
          <img alt={imgAlt} src={imgSrc} />
        </div>
      </div>
    </div>
  );
};

export default BodyContentHeader;
