import React, {useEffect, useRef, useState} from "react";
import BKCompetitiveAuditScreenshot from "../../BeeKindCaseStudyImgs/beekind_competitive_audit_screenshot.png";
import BKCrazyEights from "../../BeeKindCaseStudyImgs/beekind_crazy_8s.png";
import BKHiFiScreenshotsImg from "../../BeeKindCaseStudyImgs/beekind_hi_fi_prototype_screenshots_dma.png";
import BKLoFiScreenshotsImg from "../../BeeKindCaseStudyImgs/beekind_low_fi_prototype_screenshots_dma.png";
import BKMockupEdits from "../../BeeKindCaseStudyImgs/beekind_mockup_edits.png";
import BKMockups from "../../BeeKindCaseStudyImgs/beekind_mockups_dma.png";
import BKDrawnWireframes from "../../BeeKindCaseStudyImgs/beekind_paper_wireframes.png";
import BKParticipantScreenshot from "../../BeeKindCaseStudyImgs/beekind_participant_screenshot.png";
import BKPersona from "../../BeeKindCaseStudyImgs/beekind_persona.png";
import BKResponsiveScreens from "../../BeeKindCaseStudyImgs/beekind_responsice_screens.png";
import BKSampleInfoPage from "../../BeeKindCaseStudyImgs/beekind_sample_info_page.png";
import BKSiteMap from "../../BeeKindCaseStudyImgs/beekind_sitemap.png";
import BKUserJourneyMap from "../../BeeKindCaseStudyImgs/beekind_user_journey_map.png";
import BKEditsOne from "../../BeeKindCaseStudyImgs/beekind_wireframe_edits_1.png";
import BKEditsTwo from "../../BeeKindCaseStudyImgs/beekind_wireframe_edits_2.png";
import BodyContentHeader from "../../common/BodyContentHeader";
import "./CaseStudy.scss";

const BeeKindCaseStudy = () => {
  const TopRef = useRef(null);
  const IntroductionRef = useRef(null);
  const UserResearchRef = useRef(null);
  const StartingTheDesignRef = useRef(null);
  const UsabilityStudyRef = useRef(null);
  const RefiningTheDesignRef = useRef(null);
  const MockupsRef = useRef(null);
  const GoingForwardRef = useRef(null);
  const CaseStudyNavRef = useRef(null);
  const IntroImgRef = useRef(null);
  const ResponsiveDesignRef = useRef(null);

  const [caseStudyNavBarFixed, setCaseStudyNavBarFixed] = useState("relative");

  const scrollToSection = (Ref) => {
    Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
  };

  const stickNavbar = (e) => {
    if (window !== undefined) {
      if (
        IntroImgRef.current.getBoundingClientRect().top < 60 &&
        caseStudyNavBarFixed !== "fixed"
      ) {
        setCaseStudyNavBarFixed("fixed");
      } else if (
        IntroImgRef.current.getBoundingClientRect().top > 60 &&
        caseStudyNavBarFixed !== "relative"
      ) {
        setCaseStudyNavBarFixed("relative");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar, true);
    return () => {
      window.removeEventListener("scroll", stickNavbar, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseStudyNavBarFixed]);

  return (
    <div ref={TopRef} className="body_content_container_child">
      <BodyContentHeader
        headerTitle="Bee Kind"
        headerSubtitle="Together, we can save the bees."
        imgSrc="../notebook.svg"
        imgAlt="notebook image"
      />

      <div
        ref={CaseStudyNavRef}
        id="case_study_nav_bar"
        className={`${caseStudyNavBarFixed}`}
      >
        <button onClick={() => scrollToSection(IntroductionRef)}>Intro</button>
        <button onClick={() => scrollToSection(UserResearchRef)}>
          User Research
        </button>
        <button onClick={() => scrollToSection(StartingTheDesignRef)}>
          Drafting the Mobile App
        </button>
        <button onClick={() => scrollToSection(UsabilityStudyRef)}>
          Usability Study
        </button>
        <button onClick={() => scrollToSection(RefiningTheDesignRef)}>
          Refining app wireframes
        </button>
        <button onClick={() => scrollToSection(MockupsRef)}>
          Mobile App Mockups
        </button>
        <button onClick={() => scrollToSection(ResponsiveDesignRef)}>
          Responsive Design
        </button>
        <button onClick={() => scrollToSection(GoingForwardRef)}>
          Going Forward
        </button>
        <button onClick={() => scrollToSection(TopRef)}>Back to top</button>
      </div>

      <div ref={IntroImgRef} id="case_study_container">
        <div id="case_study_intro_img_container">
          <img
            id="case_study_intro_img"
            src="../beekind_screens_thumbnail.png"
            alt="bee kind case study intro pic"
          />
        </div>

        <h1 ref={IntroductionRef}>Introduction</h1>

        <div className="case_study_section_2_col">
          <div className="case_study_col_subsection">
            <h2>The Product:</h2>
            <h3>
              “Bee Kind” is a dedicated mobile app and responsive website, which
              educates the public on bee conservation and allows users to look
              up native plants in their area.
            </h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>Duration:</h2>
            <h3>January 2023 - March 2023</h3>
          </div>

          <div className="case_study_col_subsection">
            <h2>The Challenge:</h2>
            <h3>
              Since the 1950s, global bee populations have plummeted, and it has
              drastically damaged the world’s agricultural industries. ⅓ of all
              food is reliant on bee pollination, and bees are responsible for
              90% of all pollination worldwide.
            </h3>
          </div>

          <div className="case_study_col_subsection">
            <h2>The Goal:</h2>
            <h3>
              “Bee Kind” is designed to educate the public on sustainable
              practices and things we can all do to help save the bees. “Bee
              Kind” puts a special focus on restoring native habitats with a
              plant database to encourage planting native plants. The end
              product would not only have a mobile app, but a responsive website
              as well.
            </h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>Audience:</h2>
            <h3>
              Gardeners and people interested in learning more about bees, bee
              conservation, and how we can help save the bees.
            </h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>My Role:</h2>
            <h3>UX Designer</h3>
            <h3>
              Responsible for ideation, user research, building wireframes, user
              testing, building mockups, prototyping
            </h3>
          </div>
        </div>

        <h1 ref={UserResearchRef}>User Research</h1>

        <div className="case_study_section_1_col">
          <h3>
            To better understand my users, I made some personas, created user
            journey maps, and conducted a competitive audit. Later on, I tested
            a low-fidelity prototype with an unmoderated usability study.
          </h3>

          <div className="case_study_row_subsection">
            <img src={BKPersona} alt="bee kind persona" />
            <div className="case_study_col_subsection">
              <h2>Problem statement:</h2>
              <h3>
                "Katrin Müller is an elderly, retired woman living in rural
                Germany who needs info on finding and taking care of native,
                bee-friendly plants in her locale because it can be hard to find
                info on bee-friendly plants in her specific location. "
              </h3>
            </div>
          </div>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>User Journey Map</h2>
              <h3>
                The aim was to ideate the thought processes of someone looking
                to plant native plants in their area, especially someone like
                Katrin, whose local climate is very particular.
              </h3>
            </div>
            <img src={BKUserJourneyMap} alt="bee kind user journey map" />
          </div>

          <div className="case_study_row_subsection">
            <img
              src={BKCompetitiveAuditScreenshot}
              alt="bee kind competitive audit screenshot"
            />
            <div className="case_study_col_subsection">
              <h2>Competitive Audit</h2>
              <h3>
                To determine opportunities for filling in gaps in the market
                needs, I conducted a competitive audit on three competitors: The
                Bee Conservancy, Greenpeace, and Bee Mission. I assessed them
                based on the overall website experience, features,
                accessibility, user flow, navigation, brand identity, tone, and
                descriptiveness.
              </h3>
            </div>
          </div>
        </div>

        <h1 style={{textAlign: "center", fontSize: "35px"}}>
          The pain points:
        </h1>

        <div id="pain_points_container">
          <div className="pain_point">
            <div className="pain_point_number">1</div>
            <h3 className="pain_point_header">
              <b>Very subpar accessibility features.</b>
            </h3>
            <div className="pain_point_desc">
              The competitors had prominent issues with accessibility, include
              contrast ratios, a lack of alt tags on images, and no
              keyboard-only navigation support.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">2</div>
            <h3 className="pain_point_header">
              <b>Unclear user flow</b>
            </h3>
            <div className="pain_point_desc">
              Frequent interruptions with donation popups, lack of site maps,
              and inconsistent navigation bars make moving through user flows on
              competitor websites difficult.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">3</div>
            <h3 className="pain_point_header">
              <b>Glitchy mobile versions</b>
            </h3>
            <div className="pain_point_desc">
              The transition to mobile is not smooth. Text overflow, buggy image
              carousels, and contents leaping to different positions occur.
            </div>
          </div>
        </div>

        <h1 ref={StartingTheDesignRef}>Drafting the Mobile App</h1>

        <div className="case_study_large_img_container">
          <img
            className="case_study_large_img"
            src={BKDrawnWireframes}
            alt="bee kind hand drawn wireframes"
          />
        </div>

        <div className="case_study_section_1_col">
          <div className="case_study_row_subsection">
            <img src={BKCrazyEights} alt="bee kind crazy 8's exercise" />
            <div className="case_study_col_subsection">
              <h2>Ideation Exercises</h2>
              <h3>
                I incorporated several ideation methods to explore different
                ways to portray information on bees, including crazy eights, as
                seen here. As I quickly sketched ideas, I would mark features
                that I liked the most with stars.
              </h3>
            </div>
          </div>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>Digital Wireframes</h2>
              <h3>
                Bee Kind's star feature would be the native plant search, so it
                was imperative to organize the loads of pertinent plant info
                neatly.
              </h3>
              <h3>
                The digital wireframes were built in Figma and were later tested
                in a usability study.
              </h3>
            </div>
            <img
              src={BKSampleInfoPage}
              alt="bee kind sample plant info wireframe page"
            />
          </div>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>Low Fidelity Prototype</h2>
              <h3>
                Once the dedicated mobile app wireframes were complete, I made a
                prototype and tested it with an unmoderated usability study.
              </h3>

              <a
                className="purple_btn"
                href="https://www.figma.com/proto/9gUcMJKH7sbFMwoVKvLaEI/Bee-Kind-DMA-Wireframes?type=design&node-id=117-2&t=KaRnYlgJzjTu5g5T-1&scaling=scale-down&page-id=0%3A1&starting-point-node-id=117%3A2&show-proto-sidebar=1&mode=design"
                target="_blank"
                rel="noopener noreferrer"
              >
                Test the Low-Fi Prototype
              </a>
            </div>
            <img
              src={BKLoFiScreenshotsImg}
              alt="bee kind low fidelity prototype screenshots"
            />
          </div>
        </div>

        <h1 ref={UsabilityStudyRef}>Usability Study</h1>

        <div className="case_study_section_2_col">
          <div className="case_study_col_subsection">
            <h2>Research Goal:</h2>
            <h3>
              Determine if users can complete core tasks, such as searching for
              a plant and adding it to their favorites.
            </h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>Duration:</h2>
            <h3>March 2-5, 2023</h3>
          </div>

          <div className="case_study_col_subsection">
            <h2>Methodology:</h2>
            <h3>
              Remote, unmoderated usability study. Users followed prompts while
              recording their screen and voice as they tested the app.
            </h3>
          </div>

          <div className="case_study_col_subsection">
            <h2>Participants:</h2>
            <h3>
              One male, two females, all between the ages of 18 and 75. One
              participant is over the age of 50 with visual impairment.
            </h3>
          </div>
        </div>

        <div className="case_study_section_1_col">
          <div className="case_study_row_subsection">
            <img
              src={BKParticipantScreenshot}
              alt="bee kind participant feedback screenshot"
            />
            <div className="case_study_col_subsection">
              <h2>Participant Feedback</h2>
              <h3>
                Participant click paths, feelings, and significant quotes were
                documented on a google sheet.
              </h3>
            </div>
          </div>
        </div>

        <h1 style={{textAlign: "center", fontSize: "35px"}}>Findings:</h1>

        <div id="pain_points_container">
          <div className="pain_point">
            <div className="pain_point_number">1</div>
            <h3 className="pain_point_header">
              <b>Most gardeners require the growth zone before planting.</b>
            </h3>
            <div className="pain_point_desc">
              The growth zone is a specific zone within a biome. It dictates the
              precise elevation and temperature where a plant could possibly
              grow. 3 out of 5 study participants specifically requested this
              info.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">2</div>
            <h3 className="pain_point_header">
              <b>
                Users may want to narrow their location further than a state or
                country.
              </b>
            </h3>
            <div className="pain_point_desc">
              2 out of 5 participants wanted to search as specific as individual
              counties and zip codes.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">3</div>
            <h3 className="pain_point_header">
              <b>Users were delighted and intrigued by bee baths.</b>
            </h3>
            <div className="pain_point_desc">
              Before this study, 4 out of 5 participants had no idea what a bee
              bath was. Upon reading about it, they were delighted at how fun
              and easy it sounds.
            </div>
          </div>
        </div>

        <h1 ref={RefiningTheDesignRef}>Refining app wireframes</h1>

        <div className="case_study_section_1_col">
          <h3>
            After the usability study was completed, I used the feedback to make
            adjustments to the designs.
          </h3>

          <div className="case_study_row_subsection">
            <img
              src={BKEditsOne}
              alt="bee kind wireframe edits of health info changes"
            />
            <div className="case_study_col_subsection">
              <h2>Improvements: Add growth zone info</h2>
              <h3>
                Because participants insisted that the growth zone was crucial
                for safely planting plants in a garden, the growth zone id and
                space for a map were included.
              </h3>
            </div>
          </div>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>Improvements: Zipcode field</h2>
              <h3>
                Users wanted to narrow down their search to areas more specific
                than just a state, so the zipcode field was included to get a
                more precise locale.
              </h3>
            </div>
            <img
              src={BKEditsTwo}
              alt="bee kind wireframe edits of search options"
            />
          </div>
        </div>

        <h1 ref={MockupsRef}>Mobile App Mockups</h1>

        <div className="case_study_large_img_container">
          <img
            className="case_study_large_img"
            src={BKMockups}
            alt="bee kind mockups"
          />
        </div>

        <div className="case_study_section_1_col">
          <h3>
            Using the refined wireframes, I created mockups in Figma and
            incorporated a black, yellow, and white color palette. I felt this
            was appropriate, since these are colors associated with bees and
            honey.
          </h3>

          <div className="case_study_row_subsection">
            <img src={BKMockupEdits} alt="bee kind mockup edits" />
            <div className="case_study_col_subsection">
              <h2>Improvements: More consistent footer</h2>
              <h3>
                Previously, to sign up for the newsletter, a user would need to
                revisit the homepage. I felt like this wasn't an obvious enough
                location to simply leave the newsletter signup in a single
                place, so I added it to the footer of each mockup page. I also
                intended for a similar footer for the website, so it would be
                consistent with that as well.
              </h3>
            </div>
          </div>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>High Fidelity Mobile App Prototype</h2>
              <h3>
                Typography, images of bees pollinating flowers, and a color
                palette bring a high fidelity prototype to life, and it gives us
                a better understanding of what the app would really feel like.
              </h3>

              <a
                className="purple_btn"
                href="https://www.figma.com/proto/9gUcMJKH7sbFMwoVKvLaEI/Bee-Kind-DMA-Wireframes?node-id=206-107&scaling=scale-down&page-id=0%3A1&starting-point-node-id=206%3A107&show-proto-sidebar=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Test the High-Fi Prototype
              </a>
            </div>
            <img
              src={BKHiFiScreenshotsImg}
              alt="bee kind high fidelity prototype screenshots"
            />
          </div>
        </div>

        <h1 style={{textAlign: "center", fontSize: "35px"}}>
          Accessibility Considerations:
        </h1>

        <div id="pain_points_container">
          <div className="pain_point">
            <div className="pain_point_number">1</div>
            <h3 className="pain_point_header">
              <b>High contrast ratio.</b>
            </h3>
            <div className="pain_point_desc">
              Because the competitive audit revealed some prominent issues with
              contrast and accessibility, Bee Kind fills in the gaps for this
              need by a primarily black, white, and pastel yellow palette.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">2</div>
            <h3 className="pain_point_header">
              <b>Accessible navigation and keyboard-only compatibility.</b>
            </h3>
            <div className="pain_point_desc">
              Some competitors didn’t have sticky navigation, meaning the users
              have to scroll back to the top to navigate. They often weren’t
              focusable, either, so no keyboard-only navigation. Bee Kind’s nav
              bar is consistently placed, ensuring navigation options are always
              available.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">3</div>
            <h3 className="pain_point_header">
              <b>Continuity.</b>
            </h3>
            <div className="pain_point_desc">
              Between the dedicated mobile app and the responsive website, all
              components are similarly placed and maintain their relative looks.
              This means users can easily go between the devices without having
              to re-learn the interface.
            </div>
          </div>
        </div>

        <h1 ref={ResponsiveDesignRef}>Responsive Design</h1>

        <div className="case_study_section_1_col">
          <h3>
            Now that the dedicated mobile app design was completed, I started on
            the responsive web app. I would base this web app heavily on the
            features of the dedicated mobile app, so that there would be
            consistency and continuity for those going in between these apps.
          </h3>

          <div className="case_study_row_subsection">
            <img src={BKSiteMap} alt="bee kind site map" />
            <div className="case_study_col_subsection">
              <h2>Site Map</h2>
              <h3>
                By modeling the sitemap after the navigation of the dedicated
                mobile app, users can use either the app or website and know the
                relative locations of features.
              </h3>
            </div>
          </div>
        </div>

        <div className="case_study_large_img_container">
          <img
            className="case_study_large_img"
            src={BKResponsiveScreens}
            alt="bee kind responsive screens"
          />
        </div>

        <div className="case_study_section_1_col">
          <h3>
            I made several versions of the mockups, for different screen sizes.
            Afterwards, I created two different prototypes to test the website.
          </h3>

          <div className="case_study_row_subsection">
            <div className="case_study_col_subsection">
              <h2>Website High Fidelity Prototype</h2>
              <a
                className="purple_btn"
                href="https://www.figma.com/proto/eS5dv2UlSz3KQ3Fs2CwgRY/Bee-Kind-Web-App?node-id=48-1982&scaling=scale-down&page-id=48%3A1981&starting-point-node-id=48%3A1982"
                target="_blank"
                rel="noopener noreferrer"
              >
                Test the mobile version
              </a>
              <a
                className="purple_btn"
                href="https://www.figma.com/proto/eS5dv2UlSz3KQ3Fs2CwgRY/Bee-Kind-Web-App?node-id=13-176&scaling=scale-down&page-id=13%3A175&starting-point-node-id=13%3A176"
                target="_blank"
                rel="noopener noreferrer"
              >
                Test the desktop version
              </a>
            </div>
            <img
              src="../beekind_screens_thumbnail.png"
              alt="bee kind high fidelity prototype screenshots"
            />
          </div>
        </div>

        <h1 ref={GoingForwardRef}>Going Forward</h1>

        <div className="case_study_section_2_col">
          <div className="case_study_col_subsection">
            <h2>Impact:</h2>
            <h3>
              My peers and the participants of the usability study have learned
              a lot about bees from viewing these designs. A couple of them are
              going to build bee baths in their backyards, which is a step
              forward in bee conservation.
            </h3>
          </div>
          <div className="case_study_col_subsection">
            <h2>What I learned:</h2>
            <h3>
              I learned a lot about designing for different screen sizes and
              continuity between devices. It was a lot of work, but the end
              result was definitely worth it. I also, of course, learned a lot
              about bees.
            </h3>
          </div>
        </div>

        <h1 style={{textAlign: "center", fontSize: "35px"}}>
          My Recommended Next Steps:
        </h1>

        <div id="pain_points_container">
          <div className="pain_point">
            <div className="pain_point_number">1</div>
            <h3 className="pain_point_header">
              <b>Building up the database of plants.</b>
            </h3>
            <div className="pain_point_desc">
              There are millions of plant species worldwide, and we can’t
              restrict the native plant search to just a couple of countries.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">2</div>
            <h3 className="pain_point_header">
              <b>
                Crowdfunding and partnerships with beekeepers and bee
                researchers.
              </b>
            </h3>
            <div className="pain_point_desc">
              Currently, Bee Kind is standing alone. It could benefit from
              partnering with other organizations.
            </div>
          </div>

          <div className="pain_point">
            <div className="pain_point_number">3</div>
            <h3 className="pain_point_header">
              <b>Kids activity page.</b>
            </h3>
            <div className="pain_point_desc">
              Due to the sensation of the bee bath, it could be used to inspire
              arts and crafts with children while educating them on the
              importance of bees.
            </div>
          </div>
        </div>
        <div style={{display: "flex"}}>
          <img
            style={{width: "300px", maxWidth: "100%", margin: "auto"}}
            src="../teila_head.png"
            alt="teila's head"
          />
        </div>
        <h1 style={{textAlign: "center", fontSize: "35px", margin: "0px"}}>
          Thank you!
        </h1>
      </div>
    </div>
  );
};

export default BeeKindCaseStudy;
